import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { createGlobalStyle } from 'styled-components'
import Head from '../Head'
import Footer from '../Footer'
import PageLoader from '../PageLoader'
import { PageLoaderConsumer } from '../../state'

const GlobalStyle = createGlobalStyle`
  html {
    background-color: ${({ theme }) => theme.colors.primary};
    box-sizing: border-box;
    font-size: 62.5%;

    @media screen and (min-width: 2048px) {
      font-size: 100%;
    }
  }
  *, *:before, *:after {
    box-sizing:inherit;
  }
  body {
    margin: 0 ${({ theme }) => theme.dimensions.contentGutterSize};
    font-size:1.6rem;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.text};
  }

  ul, ol {
    list-style-position: outside;
    margin-left:0;
    padding: 0;
  }

  a {
    outline-width: 0.5rem;

    &:hover {
      outline-width: 0.5rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
`

const Main = styled.main`
  position: relative;
  z-index: 1;
  margin: 0 auto;
  width: 100%;
  max-width: ${({ theme }) => theme.dimensions.contentWidth};
  padding: 0 2rem;
`

const Layout = ({ children, headData = null }) => (
  <PageLoaderConsumer>
    {({ loading }) => (
      <Fragment>
        <Head {...headData} />
        <GlobalStyle />
        {loading && <PageLoader />}
        {!loading &&
          <Main>
            {children}
          </Main>
        }
        <Footer />
      </Fragment>
    )}
  </PageLoaderConsumer>
)

Layout.propTypes = {
  children: PropTypes.node,
  headData: PropTypes.object
}

export default Layout
