import React, { Fragment } from 'react'
import styled from 'styled-components'
import { ThemeConsumer } from '../../state'
import { FaBookOpen } from 'react-icons/fa'
import Query from './Query'
import config from '../../config'

const Container = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.dimensions.contentWidth};
  padding: 0 2rem;
  margin: 0 auto;
  display: block;
  letter-spacing: 0.08em;
  margin-top: 15rem;
  margin-bottom: 5rem;

  img {
    margin: 0;
  }

  a {
    color: ${({ theme }) => theme.colors.interactive};
    text-decoration: none;
  }
`

const Upper = styled.div`
  font-size: 0.9em;
  text-transform: uppercase;
  display: flex;
  padding-bottom: 2.4rem;
  margin-bottom: 2.4rem;
  border-bottom: 0.1rem solid ${({ theme }) => theme.colors.pageRule};

  .copyright {
    font-size: 0.85em;
    color: ${({ theme }) => theme.colors.text};
    opacity: 0.7;
    margin: 0;
    margin-top: 0.1rem;
  }

  @media screen and (max-width: 1000px) {
    display: block;
    text-align: center;
  }

  @media screen and (max-width: 570px) {
    text-align: left;
  }
`

const LeftLinksContainer = styled.div`
  flex: 1;

  > p,
  > a,
  > div {
    display: inline-block;
    vertical-align: top;
    margin: 0 1.6rem;
  }

  .support-links {
    margin-left: 0.6rem;
    margin-right: 0;

    > div {
      display: inline-block;
      position: relative;
      padding: 0 1.6rem;

      &:first-of-type {
        padding-left: 0;
      }

      &:after {
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translate(-50%, -50%);
        content: '';
        display: block;
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 0.3rem;
        background-color: ${({ theme }) => theme.colors.text};
        opacity: 0.7;
      }

      &:last-of-type:after {
        display: none;
      }
    }
  }

  img {
    width: 2.4rem;
  }

  @media screen and (max-width: 570px) {
    .support-links {
      display: block;
      margin: 0;

      > div {
        display: block;
        padding: 0;
        margin-top: 1.2rem;

        &:first-of-type {
          margin-top: 0.2rem;
        }

        &:after {
          display: none;
        }
      }
    }
  }
`

const RightLinksContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  flex-direction: column;

  > div {
    display: flex;

    .iohk-logo {
      > img,
      > span {
        vertical-align: top;
      }

      > span {
        margin-left: 0.8rem;
        color: ${({ theme }) => theme.colors.text};
        opacity: 0.7;
      }

      img {
        width: 3rem;
      }
    }

    .social-media {
      padding-left: 1.6rem;
      display: flex;
      align-items: center;

      a {
        flex: 1;
        padding: 0 0.2rem;
        display: flex;
        color: ${({ theme }) => theme.colors.text};
        opacity: 0.3;

        &:last-of-type {
          margin-left: 0.4rem;
          opacity: 0.4;
          font-size: 2rem;
        }

        &:nth-of-type(3) {
          margin: 0 0.6rem;
        }
      }

      img {
        flex: 1;
        width: 2.6rem;
        max-height: 1.8rem;
      }
    }
  }

  @media screen and (max-width: 1000px) {
    align-items: center;
    margin-top: 0.8rem;
  }

  @media screen and (max-width: 570px) {
    align-items: normal;
    margin-top: 1.2rem;

    > div {
      display: block;

      .social-media {
        padding: 0;
        display: block;
        margin-top: 1.2rem;

        > a,
        > a:nth-of-type(3),
        > a:last-of-type {
          display: inline-block;
          margin: 0 1.6rem;

          &:first-of-type {
            margin-left: 0;
          }

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }
  }
`

const Lower = styled.div`
  display: flex;
  font-size: 0.75em;

  .title {
    font-weight: 600;
    opacity: 0.7;
  }

  @media screen and (max-width: ${config.mobileHeaderBreakPoint - 1}px) {
    display: block;
  }
`

const Disclaimer = styled.div`
  flex: 1.5;
  margin-right: 1.6rem;

  .body {
    text-align: justify;
    max-width: 60rem;
  }

  @media screen and (max-width: 1000px) {
    flex: 1;

    .body {
      max-width: none;
    }
  }

  @media screen and (max-width: ${config.mobileHeaderBreakPoint - 1}px) {
    margin-right: 0;
  }
`

const LowerLinksContainer = styled.div`
  flex: 1;
  margin-left: 1.6rem;

  @media screen and (max-width: ${config.mobileHeaderBreakPoint - 1}px) {
    margin-left: 0;
    margin-top: 2.4rem;
  }
`

const LinksColumn = styled.div`
  display: inline-block;
  width: 50%;
  box-sizing: border-box;
  vertical-align: top;

  &.left {
    padding-right: 0.8rem;
  }

  &.right {
    padding-left: 0.8rem;
  }

  ul {
    list-style: none;

    li {
      margin: 0.2rem 0;

      a {
        color: ${({ theme }) => theme.colors.text};
        transition: color 0.2s ease-in-out;

        &.mail {
          color: ${({ theme }) => theme.colors.interactive};
        }

        &:hover {
          color: ${({ theme }) => theme.colors.interactive};
        }
      }
    }
  }

  @media screen and (max-width: 460px) {
    width: 100%;
    margin-top: 1.6rem;

    &.left {
      padding-right: 0;
    }

    &.right {
      padding-left: 0;
    }

    .title {
      border-bottom: 0.1rem solid ${({ theme }) => theme.colors.pageRule};
      padding-bottom: 1.6rem;
      display: inline-block;
    }

    ul {
      li {
        margin: 0.4rem 0;
      }
    }
  }
`

const CREATION_YEAR = 2015
const getCurrentYear = () => new Date().getFullYear()

const Footer = () => (
  <Container>
    <Query
      render={data => (
        <ThemeConsumer>
          {({ theme }) => (
            <Fragment>
              <Upper>
                <LeftLinksContainer>
                  <p className='copyright'>
                    Cardano {CREATION_YEAR} - {getCurrentYear()}
                  </p>
                  <a href='https://github.com/input-output-hk' title='Input Output HK GitHub' target='_blank'>
                    <img src={theme.images.githubMark} alt='GitHub Mark' />
                  </a>
                  <div className='support-links'>
                    <div>
                      <a href='#'>{data.support}</a>
                    </div>
                    <div>
                      <a href='#'>{data.media_enquiries}</a>
                    </div>
                  </div>
                </LeftLinksContainer>
                <RightLinksContainer>
                  <div>
                    <a className='iohk-logo' href='https://iohk.io/' target='_blank'>
                      <img src={theme.images.IOHKSymbol} alt='IOHK Logo' /><span>IOHK Supported Project</span>
                    </a>
                    <div className='social-media'>
                      <a href='https://twitter.com/inputoutputHK' target='_blank'>
                        <img src={theme.images.twitterLogo} alt='Twitter Logo' />
                      </a>
                      <a href='https://www.facebook.com/iohk.io/' target='_blank'>
                        <img src={theme.images.facebookLogo} alt='Facebook Logo' />
                      </a>
                      <a href='http://www.youtube.com/c/IohkIo' target='_blank'>
                        <img src={theme.images.youtubeIcon} alt='YouTube Icon' />
                      </a>
                      <a href='https://iohk.io/blog/' target='_blank'>
                        <FaBookOpen />
                      </a>
                    </div>
                  </div>
                </RightLinksContainer>
              </Upper>
              <Lower>
                <Disclaimer>
                  <p className='title'>
                    {data.blob_title.split('$1')[0]}
                    <a href='https://en.wikipedia.org/wiki/Open-source_software' target='_blank'>Open Source</a>
                    {data.blob_title.split('$1')[1]}
                  </p>
                  <p className='body' dangerouslySetInnerHTML={{ __html: data.html }} />
                </Disclaimer>
                <LowerLinksContainer>
                  <LinksColumn className='left'>
                    <p className='title'>
                      {data.cardano_info}
                    </p>
                    <ul>
                      {data.cardano_links.map((link, index) => (
                        <li key={`${index}${link.href}`}><a href={link.href} target='_blank'>{link.label}</a></li>
                      ))}
                    </ul>
                  </LinksColumn>
                  <LinksColumn className='right'>
                    <p className='title'>
                      {data.community_info}
                    </p>
                    <ul>
                      {data.community_links.map((link, index) => (
                        <li key={`${index}${link.href}`}><a href={link.href} target='_blank'>{link.label}</a></li>
                      ))}
                      <li><a href={`mailto:${data.community_email}`} className='mail' target='_blank'>{data.community_email}</a></li>
                    </ul>
                  </LinksColumn>
                </LowerLinksContainer>
              </Lower>
            </Fragment>
          )}
        </ThemeConsumer>
      )}
    />
  </Container>
)

export default Footer
