import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { LanguageConsumer } from '../../state'
import { getURIPathWithoutLang, getHref } from '../../helpers/url'

function getHeadData ({ componentHeadData, pageHeadData, siteHeadData }) {
  const filteredPageHeadData = {}
  Object.keys(pageHeadData).forEach(key => {
    if (pageHeadData[key]) filteredPageHeadData[key] = pageHeadData[key]
  })

  const metaNames = []
  const meta = []
  const unfilteredMeta = [ ...(componentHeadData.meta || []), ...(filteredPageHeadData.meta || []), ...(siteHeadData.meta || []) ]
  unfilteredMeta.forEach((tag) => {
    if (!tag.content && !tag.file) return
    if (!metaNames.includes(tag.name)) meta.push({ name: tag.name, content: tag.content || getHref(tag.file) })
    metaNames.push(tag.name)
  })

  return {
    title: componentHeadData.title || filteredPageHeadData.title || siteHeadData.title,
    meta
  }
}

const Layout = ({ title, meta }) => (
  <LanguageConsumer>
    {({ lang }) => (
      <StaticQuery
        query={graphql`
          query {
            siteHeadData: allFile(filter:{relativePath:{glob:"content/site/*.md"}}) {
              nodes{
                relativePath,
                childMarkdownRemark{
                  frontmatter {
                    head {
                      title
                      meta {
                        name
                        content
                        file
                      }
                    }
                  }
                }
              }
            }
            pageHeadData: allFile(filter:{relativePath:{regex:"/^content\/pages\/.*\\.md$/"}}) {
              nodes{
                relativePath,
                childMarkdownRemark{
                  frontmatter {
                    head {
                      title
                      meta {
                        name
                        content
                        file
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={({ siteHeadData, pageHeadData }) => {
          const pageContentPath = getURIPathWithoutLang(lang) === '/'
            ? 'index'
            : getURIPathWithoutLang(lang).replace(/^\//, '').replace(/\/$/, '')
          const page = pageHeadData.nodes.filter(node => node.relativePath === `content/pages/${pageContentPath}/${lang}.md`).shift()
          const site = siteHeadData.nodes.filter(node => node.relativePath === `content/site/${lang}.md`).shift()
          const completeHeadData = getHeadData({
            componentHeadData: { title, meta },
            pageHeadData: (page && page.childMarkdownRemark.frontmatter && page.childMarkdownRemark.frontmatter.head) || {},
            siteHeadData: (site && site.childMarkdownRemark.frontmatter && site.childMarkdownRemark.frontmatter.head) || {}
          })

          return (
            <Helmet
              title={completeHeadData.title}
              meta={completeHeadData.meta}
            >
              <html lang={lang} />
            </Helmet>
          )
        }}
      />
    )}
  </LanguageConsumer>
)

Layout.propTypes = {
  title: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    content: PropTypes.string,
    file: PropTypes.string
  }))
}

export default Layout
