import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import { ThemeConsumer } from '../../state'

const CIRCLES_IN_ARRAY = 6
const θ = (Math.PI * 2) / CIRCLES_IN_ARRAY

function getPosition (i) {
  const coords = {
    x: -(50 * Math.sin(θ * i)),
    y: 50 * Math.cos(θ * i)
  }

  return {
    left: coords.x + 50,
    top: coords.y + 50
  }
}

function getKeyframes (theme) {
  return keyframes`
    0% {
      fill: ${theme.colors.text};
      opacity: 0.3;
    }

    25% {
      fill: ${theme.colors.interactive};
      opacity: 1;
    }

    50% {
      fill: ${theme.colors.text};
      opacity: 0.3;
    }
  `
}

const Circle = styled.svg`
  left: ${({ position }) => position.left}%;
  top: ${({ position }) => position.top}%;
  animation: ${({ theme }) => getKeyframes(theme)};
  animation-duration: 3.5s;
  animation-timing: ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: ${({ offset }) => offset * 0.3}s;
  opacity: 0.3;
`

function getCircleArray (theme, size, offset) {
  return new Array(CIRCLES_IN_ARRAY).fill(1).map((_, i) => (
    <Circle key={i} position={getPosition(i)} offset={offset} width={size * 2} height={size * 2} fill={theme.colors.text}>
      <circle cx={size} cy={size} r={size} />
    </Circle>
  ))
}

const fullRotation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    tranform: rotate(180deg);
  }
`

const Container = styled.div`
  position: relative;
  width: ${({ size }) => size}rem;
  height: ${({ size }) => size}rem;
  animation: ${fullRotation};
  animation-timing: linear;
  animation-iteration-count: infinite;
  animation-duration: 8s;

  > div {
    position: absolute;
    left: 50%;
    top: 50%;

    svg {
      transform: translate(-50%, -50%);
      position: absolute;
    }
  }
`

const InnerCircleContainer = styled.div`
  width: 30%;
  height: 30%;
  transform: translate(-50%, -50%) rotate(${180 / CIRCLES_IN_ARRAY}deg);
`

const MidInnerCircleContainer = styled.div`
  width: 50%;
  height: 50%;
  transform: translate(-50%, -50%) rotate(0deg);
`

const MidOuterCircleContainer = styled.div`
  width: 64%;
  height: 64%;
  transform: translate(-50%, -50%) rotate(${180 / CIRCLES_IN_ARRAY}deg);
`

const OuterCircleContainer = styled.div`
  width: 80%;
  height: 80%;
  transform: translate(-50%, -50%) rotate(0deg);
`

const OuterOuterCircleContainer = styled.div`
  width: 87%;
  height: 87%;
  transform: translate(-50%, -50%) rotate(${180 / CIRCLES_IN_ARRAY}deg);
`

const Loader = ({ size }) => (
  <Container size={size}>
    <ThemeConsumer>
      {({ theme }) => (
        <Fragment>
          <InnerCircleContainer>
            {getCircleArray(theme, size / 1.5, 0)}
          </InnerCircleContainer>
          <MidInnerCircleContainer>
            {getCircleArray(theme, size / 3, 1)}
          </MidInnerCircleContainer>
          <MidOuterCircleContainer>
            {getCircleArray(theme, size / 3.5, 2)}
          </MidOuterCircleContainer>
          <OuterCircleContainer>
            {getCircleArray(theme, size / 3.8, 3)}
          </OuterCircleContainer>
          <OuterOuterCircleContainer>
            {getCircleArray(theme, size / 4, 4)}
          </OuterOuterCircleContainer>
        </Fragment>
      )}
    </ThemeConsumer>
  </Container>
)

Loader.propTypes = {
  size: PropTypes.number
}

Loader.defaultProps = {
  size: 10
}

export default Loader
